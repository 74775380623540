export function parseJwt(token) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

export const checkAuditor = () => {
  const getToken = localStorage.getItem("token");
  const user = parseJwt(getToken);
  const user_roles = user.data.user_roles;
  return user_roles.includes(5) && user_roles.length === 1;
};

export const checkAdmin = () => {
  const getToken = localStorage.getItem("token");
  const user = parseJwt(getToken);
  const user_roles = user.data.user_roles;
  return user_roles.includes(0);
};

export const checkSales = () => {
  const getToken = localStorage.getItem("token");
  const user = parseJwt(getToken);
  const user_roles = user.data.user_roles;
  return user_roles.includes(6) && user_roles.length === 1;
};

export const checkHr = () => {
  const getToken = localStorage.getItem("token");
  const user = parseJwt(getToken);
  const user_roles = user.data.user_roles;
  return user_roles.includes(7) && user_roles.length === 1;
};
