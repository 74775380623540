import React from 'react'
import './Uploading.css'
const Uploading = () => {
  return (
    <div className='uploading-container'>
        UPLOADING......
    </div>
  )
}

export default Uploading