import axios from "axios";

const getRequest = async (url) => {
  const token = localStorage.getItem("token");
  const bearer_token = "bearer " + JSON.parse(token);
  const requestConfig = {
    headers: {
      Authorization: bearer_token,
    },
  };
  return await axios.get(url, requestConfig);
};

const postRequest = async (url, data) => {
  const token = localStorage.getItem("token");
  const bearer_token = "bearer " + JSON.parse(token);
  const requestConfig = {
    headers: {
      Authorization: bearer_token,
    },
  };
  return await axios.post(url, data, requestConfig);
};

const putRequest = async (url, data) => {
  const token = localStorage.getItem("token");
  const bearer_token = "bearer " + JSON.parse(token);
  const requestConfig = {
    headers: {
      Authorization: bearer_token,
    },
  };
  return await axios.put(url, data, requestConfig);
};

const patchRequest = async (url, data) => {
  const token = localStorage.getItem("token");
  const bearer_token = "bearer " + JSON.parse(token);
  const requestConfig = {
    headers: {
      Authorization: bearer_token,
    },
  };
  return await axios.patch(url, data, requestConfig);
};

export { getRequest, postRequest, putRequest, patchRequest };
